import React, { useState } from "react";
import HeaderContactUs from "../../components/headerContactUs/HeaderContactUs";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const options = [
  { value: "Other", label: "Other" },
  { value: "UIUX Design", label: "UIUX Design" },
  { value: "Mobile Apps development", label: "Mobile Apps development" },
  { value: "Hosting", label: "Hosting" },
  { value: "Network services", label: "Network services" },
  { value: "Digital Marketing", label: "Digital Marketing" },
  { value: "Graphic Design", label: "Graphic Design" },
];

export default function SendOrder() {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [order, setOrder] = useState({
    first_name: null,
    last_name: null,
    email: null,
    message: null
  });
  const [items, setItems] = useState({});
  const [flag, setFlag] = useState(false)

  const onChange = () => {
    setFlag(true)
  }
 

  const clickHandler = async () => {
    setLoading(true)

    if (!flag) {
      toast.error("Please check the recaptcha!", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false)
      return
    }
    const body = {
      ...order,
      phone: value,
      items
    }
    try {
      const response = await axios.post('https://api.beyond-it.info/api/order', body)
      console.log(response)
      toast.success("Your order submitted!", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false)
    } catch (error) {

      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false)

      }
    }
  };

  return (
    <div className="header-container-contact headerContactUs">
      <HeaderContactUs />
      <div className="header-contact">
        <div className="header-contact-title">
          <h4>Send order</h4>
          <p>Please specify your information and the details of your request</p>
        </div>
        <div className="header-contact-information">
          <div className="header-contact-left">
            <div className="header-contact-circle-left">
              <div className="contact-right-text">
                <h5>Contact Information</h5>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>
              </div>
              <div className="contact-header-icons-left">
                <div className="contact-right-icons">
                  <img src="./images/phone 4.svg" alt="phone" />
                  <span className="phone-number-one">+1(818) 930-4747</span>
                </div>
                <div className="contact-right-icons">
                  <img src="./images/post 4.svg" alt="post" />
                  <span>beondit@gmail.com</span>
                </div>
                <div className="contact-right-icons">
                  <img src="./images/map 4.svg" alt="map" />
                  <span>7451 Garden Grove ave Reseda CA 91335</span>
                </div>
                <div className="contact-bottom-circle">
                  <div className="contact-right-icon">
                    <img
                      src="./images/circle.svg"
                      alt="circle"
                      className="right-icon-circle"
                    />
                    <img
                      src="./images/Semicircular.svg"
                      alt="circle"
                      className="right-icon-Semicircular"
                    />
                  </div>
                  <div className="icon-contact">
                    <img src="./images/Facebook.svg" alt="Facebook" />
                    <img src="./images/Twitter 4.svg" alt="Twitter" />
                    <img src="./images/instagram (1).svg" alt="Instagram" />
                    <img src="./images/linkedin 5.svg" alt="linkedin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-contact-right">
            <div className="contact-right-name">
              <div className="contact-right-input">
                <label className="firstName">First Name <span style={{ "color": 'red' }}>*</span></label>
                <input
                  placeholder="Micheal"
                  onChange={(e) => setOrder((perv) => ({ ...perv, first_name: e.target.value }))}
                />
              </div>
              <div className="contact-right-input">
                <label>Last name <span style={{ "color": 'red' }}>*</span></label>
                <input
                  onChange={(e) => setOrder((perv) => ({ ...perv, last_name: e.target.value }))}
                  placeholder="Watson"
                  required
                />
              </div>
            </div>
            <div className="contact-right-name">
              <div className="contact-right-input">
                <label>Email address <span style={{ "color": 'red' }}>*</span></label>
                <input
                  onChange={(e) => setOrder((perv) => ({ ...perv, email: e.target.value }))}
                  type="email"
                  placeholder="michealwatson@gmail.com"
                />
              </div>
              <div className="contact-right-input">
                <label>Phone (optional)</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="+1  123456789"
                  value={value}
                  onChange={setValue}
                  className={`phoneInputTag `}
                />
              </div>
            </div>
            <div className="contact-left-input">
              <label className="yourOrder">Your order <span style={{ "color": 'red' }}>*</span></label>
              <Select options={options} isMulti className="selectMulti" onChange={(selected) => setItems(selected)} />
            </div>
            <div className="contact-left-massage">
              <label>massage <span style={{ "color": 'red' }}>*</span></label>
              <input
                type="massage"
                placeholder="Write your message..."
                className="message"
                onChange={(e) => setOrder((perv) => ({ ...perv, message: e.target.value }))}
              />

              <div style={{ display:'flex',justifyContent:'space-between',alignItems: 'center' }}>
                <ReCAPTCHA
                  sitekey="6LcUqD4pAAAAAPixsUIZDuveh7dmgeFYT9VHC4ws"
                  onChange={onChange}
                />

                <button className="contact-left-submit" onClick={clickHandler} disabled={loading}>
                  {loading ? 'sending Information ...' : 'submit'}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
