import React from "react";
import Header from "../Header/HeaderComponent";
import "./headerPortfolio.css";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";

export default function HeaderPortfolio() {
  const handleClick = async() => {
    console.log("object")
    try {
      // Show toast
      toast.success('phone copied!', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
  
      // Copy text to clipboard
      await navigator.clipboard.writeText('+1(818)930-4747');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }
  const mailtoHref = "mailto: support@beyond-it.info?subject=SendMail&body=Description";
  return (
    <div className="header-container HeaderPortfolio">
      <Header />
      <div className="box-headerPortfolio">
        <div className="headerPortfolio-img">
          <img
            src="./images/Frame10.png"
            alt="Frame10"
            className="header-portfolio-img"
          />
        </div>
        <div className="headerPortfolio-text">
          <div className="heading-text-portfolio">
            <h3 className="title-header-portfolio">Portfolio</h3>
            <span className="text-header-portfolio">
              You can see some examples of our works below, which are done based
              on the needs of the customers and the capabilities of the BEOND IT
              team.
            </span>
          </div>
          <div className="button-header-portfolio">
            <div className="button-numberPhone-portfolio">
              <button className="button-number-headerContainer button-phone-portfolio" onClick={handleClick}>
                <BsTelephoneFill />
                <IoIosArrowForward />
                +1-(818)930-4747
              </button>
            </div>
            <div className="button-email-portfolio">
              <button className="button-headerContainer-send button-email-portfolio">
                <img
                  src="../images/send-order.svg"
                  alt="path73"
                  className="envelope"
                />
                <img
                  src="../images/shee56.svg"
                  alt="shee"
                  className="envelope1"
                />
                <span>
                  <a href={mailtoHref} className="btn btn-danger">
                      support@beyond-it.info
                  </a>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
