import React, { useEffect, useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { MobileNavigation } from "../mobileNavigation/MobileNavigation";
import "./header.css";
import { ToastContainer, toast } from "react-toastify";

export default function HeaderComponent() {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".header-sticky");
    const scrollTop = window.scrollY;
    scrollTop >= 150
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const handleClick = async() => {
    try {
      // Show toast
      toast.success('phone copied!', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
  
      // Copy text to clipboard
      await navigator.clipboard.writeText('+1(818)930-4747');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }
  return (
    <>
      <MobileNavigation isOpen={isOpen} closeMenu={() => setOpen(false)} />

      <div className="header-sticky">
        <div className="header">
          <div>
            <div className="header-left-laptop">
              <span className="header-left-circle"></span>
              <span className="header-left-text">BEYOND IT</span>
            </div>
            <img
              src="./images/menu.svg"
              className="menu-mobile"
              alt="menu mobile"
              onClick={() => setOpen(true)}
            />
          </div>
          <div className="header-div-center">
            <ul className="menu-header">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>Home</li>
              </NavLink>
              <NavLink
                to="/Service"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>Services </li>
              </NavLink>
              <NavLink
                to="/Portfolio"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>Portfolio</li>
              </NavLink>
              <NavLink
                to="/AboutUs"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>About us</li>
              </NavLink>
              <NavLink
                to="/ContactUs"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>Contact us</li>
              </NavLink>
            </ul>
            <div className="menu-laptop2">
              <span className="circleCenterHeader"></span>
              <span className="textCircleCenterHeader">BEYOND IT</span>
            </div>
          </div>
          <div>
            <button className="button-number" onClick={handleClick}>
              <BsTelephoneFill />
              <IoIosArrowForward />
              +1(818)930-4747
            </button>

            <button className="phone-mobile">
              <BsTelephoneFill />
            </button>
          </div>
        </div>
        <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      </div>
    </>
  );
}
