import React from "react";
import Header from "../Header/HeaderComponent";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { RxEnvelopeClosed } from "react-icons/rx";
import "./headerAbout.css";
import { ToastContainer, toast } from "react-toastify";

export default function HeaderAbout() {
  const mailtoHref = "mailto: support@beyond-it.info?subject=SendMail&body=Description";

  const handleClick = async() => {
    console.log("object")
    try {
      // Show toast
      toast.success('phone copied!', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
  
      // Copy text to clipboard
      await navigator.clipboard.writeText('+1(818)930-4747');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }
  return (
    <div className="header-container header-about-container">
      <Header />
      <div className="header-about-text">
        <h3>About us</h3>
        <div className="header-about">
          {/* <p>
            The beyond IT development team started its activity
            <br /> in early 2021 by designing and building several <br />
            applications and sites for friends. This good
            <br /> experience made us take bigger steps with <br /> more
            seriousness and with the aim of providing fair <br />
            and professional services to all businesses. Our slogan is always:
            <br />
            We consider customers as our friends <br />
            and partners and will do our best to satisfy them.
          </p> */}
          <div className="headerBoxButton" id="buttons-about">
            <button className="button-number-headerContainer" onClick={handleClick}>
              <BsTelephoneFill />
              <IoIosArrowForward />
              <span >+1(818)930-4747</span>
            </button>
            <button className="button-headerContainer-send" id="button-about2">
              <RxEnvelopeClosed />
              <IoIosArrowForward />
              <span ><a href={mailtoHref} className="" style={{ color: 'black' }}>
                support@beyond-it.info
              </a>
              </span>
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
