import React, { useState } from "react";
import HeaderContactUs from "../../components/headerContactUs/HeaderContactUs";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./ContactUs.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs() {
  const [value, setValue] = useState();
  const [items, setItems] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
   setItems(prev=>({...prev,[e.target.name]:e.target.value}))
  };

  const [flag, setFlag] = useState(false)

  const onChange = () => {
    setFlag(true)
  }

  const clickHandler = async() => {
    setLoading(true)

    if (!flag) {
      toast.error("Please check the recaptcha!", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false)
      return
    }

   const body={
    ...items,
    phone:value
   }
   try {
    const response = await axios.post('https://api.beyond-it.info/api/contact',body)
    
    toast.success(response.data, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setLoading(false)
   } catch (error) {
    setLoading(false)

    toast.error(error.response.data.message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setLoading(false)
   }
  };

  return (
    <div className="header-container header-container-contact headerContactUs">
      <HeaderContactUs />
      <div className="header-contact">
        <div className="header-contact-title">
          <h4>Contact us</h4>
          <p>Any question or remarks? Just drop us a line!</p>
        </div>
        <div className="header-contact-information">
          <div className="header-contact-left">
            <div className="header-contact-circle-left">
              <div className="contact-right-text">
                <h5>Contact Information</h5>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>
              </div>
              <div className="contact-header-icons-left">
                <div className="contact-right-icons">
                  <img src="./images/phone 4.svg" alt="phone" />
                  <span className="phone-number-one">+1(818) 930-4747</span>
                </div>
                <div className="contact-right-icons">
                  <img src="./images/post 4.svg" alt="post" />
                  <span>support@beyond-it.info</span>
                </div>
                <div className="contact-right-icons">
                  <img src="./images/map 4.svg" alt="map" />
                  <span>7451 Garden Grove ave Reseda CA 91335</span>
                </div>
                <div className="contact-bottom-circle">
                  <div className="contact-right-icon">
                    <img
                      src="./images/circle.svg"
                      alt="circle"
                      className="right-icon-circle"
                    />
                    <img
                      src="./images/Semicircular.svg"
                      alt="circle"
                      className="right-icon-Semicircular"
                    />
                  </div>
                  <div className="icon-contact">
                    <img src="./images/Facebook.svg" alt="Facebook" />
                    <img src="./images/Twitter 4.svg" alt="Twitter" />
                    <img src="./images/instagram (1).svg" alt="Instagram" />
                    <img src="./images/linkedin 5.svg" alt="linkedin" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-contact-right">
            <div className="contact-right-name">
              <div className="contact-right-input">
                <label className="firstName">First Name</label>
                <input
                 type="text"
                 name="first_name"
                 onChange={(e)=>handleChange(e)}
                  placeholder="Micheal"
                />
              </div>
              <div className="contact-right-input">
                <label>Last name</label>
                <input
                  type="text"
                  name="last_name"
                  onChange={(e)=>handleChange(e)}
                  placeholder="Watson"
                  
                />
              </div>
            </div>
            <div className="contact-right-name">
              <div className="contact-right-input">
                <label>Email address</label>
                <input
                  name="email"
                  onChange={(e)=>handleChange(e)}
                  type="email"
                  placeholder="michealwatson@gmail.com"
                />
              </div>
              <div className="contact-right-input">
                <label>Phone</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="+1  123456789"
                  value={value}
                  onChange={setValue}
                  className={`phoneInputTag ${value ? "success" : " "}`}
                />
              </div>
            </div>
            <div className="contact-left-massage">
              <label>Message</label>
              <textarea
                placeholder="Write your message..."
                className="message"
                 name="message"
                 onChange={(e)=>handleChange(e)}
              />
                <div style={{ display:'flex',justifyContent:'space-between',alignItems: 'center' }}>
                <ReCAPTCHA
                  sitekey="6LcUqD4pAAAAAPixsUIZDuveh7dmgeFYT9VHC4ws"
                  onChange={onChange}
                />

                <button className="contact-left-submit" onClick={clickHandler} disabled={loading}>
                  {loading ? 'sending Information ...' : 'submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
