import React from "react";
import Header from "../Header/HeaderComponent";
import "./headerService.css";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default function HeaderServices() {
  const handleClick = async() => {
    console.log("object")
    try {
      // Show toast
      toast.success('phone copied!', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
  
      // Copy text to clipboard
      await navigator.clipboard.writeText('+1(818)930-4747');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }
  return (
    <div className="header-container" id="header-services">
      <Header />
      <div className="headerBoxService">
        <div className="header-services-text">
          <h3>
            Our <br /> Services
          </h3>
          <p>Do you like sensitive and difficult work?</p>
          <p>
            Beyond IT provides you with the best quality website design
            <br /> services by expert and experienced people.
          </p>
          <div className="headerBoxButton-service">
            <button className="button-number-headerContainer" onClick={handleClick}>
              <BsTelephoneFill />
              <IoIosArrowForward />
              +1(818)930-4747
            </button>
            <button className="button-headerContainer-send">
              <Link to="/SendOrder">
                <img src="../images/send-order.svg" alt="path73" />
                <IoIosArrowForward />
                <span>SEND ORDER</span>
              </Link>
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
