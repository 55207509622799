import * as React from "react";
import HeaderTerms from "../../components/headerTerms/HeaderTerms";
import "./terms.css";
import TextPrivacy from "../../components/textTerms/TextPrivacy";

export default function Privacy() {
  return (
    <div>
      <HeaderTerms  title="Privacy policy" date="Dec 8, 2023"/>
      <TextPrivacy />
    </div>
  );
}
