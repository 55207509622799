import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "../Box/Box";
import "./footer.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function Footer() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const inputClick = async() => {
    setLoading(true)
    try {
      const response = await axios.post('https://api.beyond-it.info/api/email', {email})
   
      if(response.status === 200){
        toast.success("Email registered successfully!", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false)
      }
    } catch (error) {

      if(error.response.status === 422){
       
        toast.error(error.response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false)
      }
    }
  };

  const top = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const handlerScroll = () => {
      const scrollProgress = document.getElementsByClassName("icons-bottom")[0];
      if (window.scrollY > 300) {
        scrollProgress.style.display = "block";
      } else {
        scrollProgress.style.display = "none";
      }
    };
    window.addEventListener("scroll", handlerScroll);
    return () => {
      window.removeEventListener("scroll", handlerScroll);
    };
  }, []);



  return (
    <div className="footer">
      <Box>
        <div className="contact">
          <p>
            You can always contact us and be aware of the latest news and
            information
          </p>

          <div className="button-submit">
            <input
              type="email"
              placeholder="Type your e-mail address"
              className="inputs"
              onChange={(e)=>setEmail(e.target.value)}
            />
            <input
              type="submit"
              placeholder="Type your e-mail address"
              className="input1"
              onClick={inputClick}
              value={loading ? 'submitting ... ' : 'submit'}
              disabled={loading}
            />
          </div>
          <input
            type="Submit"
            placeholder="Type your e-mail address"
            className="input2"
            value={loading ? 'submitting ... ' : 'submit'}
            disabled={loading}
          />
        </div>
        <div className="icons">
          <div onClick={top} className="icons-bottom">
            <img src="./images/icon.svg" alt="icon1" className="icon1" />
            <img src="./images/path.svg" alt="icon2" className="icon2" />
          </div>
          <div className="icon-img1">
            <img src="./images/icon1.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon2.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon3.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon4.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon5.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon6.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon">
            <img src="./images/icon7.svg" alt="icon" className="icon-img" />
          </div>
          <div className="icon-img1">
            <img
              src="./images/icon-footer.svg"
              alt="icon"
              className="icon-img"
            />
          </div>
        </div>
      </Box>
      <div className="footer-button">
        <div>
          <div className="header-footer">
            <div>
              <img
                src="./images/headers.svg"
                className="header-icon"
                alt="menu laptop"
              />
            </div>
            <div>
              <ul className="footer-ul" id="footer-header-ul">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Service">Services</Link>
                </li>
                <li>
                  <Link to="/Portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/AboutUs">About us</Link>
                </li>
                <li>
                  <Link to="/ContactUs">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-icon">
          <div className="footer-description">
            <div className="footer-description-item">
              <div className="description-icon">
                <div className="footer-icons-right">
                  <div
                    className="footer-icon-right"
                    id="footer-icon-right-desktop"
                  >
                    <img src="./images/map.svg" alt="map" />
                    <span>7451 Garden Grove ave Reseda CA 91335</span>
                  </div>
                  <div
                    className="footer-icon-right"
                    id="footer-icon-right-mobile"
                  >
                    <img src="./images/map.svg" alt="map" />
                    <span>
                      7451 Garden Grove ave
                      <br /> Reseda CA 91335
                    </span>
                  </div>
                  <div className="footer-icon-right" id="footer-icon-phone">
                    <img src="./images/phones.svg" alt="phone" />
                    <div className="phone-span">
                      <span>+1(818) 930-4747</span>
                      <span>+374 91 566714</span>
                    </div>
                  </div>
                  <div className="footer-icon-right" id="footer-icon-post">
                    <img src="./images/post.svg" alt="post" />
                    <span>support@beyond-it.info</span>
                  </div>
                </div>
                <div className="footer-icon-left">
                  {/* <a href="#whatsapp">
                    <img
                      src="./images/Whatsapp.svg"
                      alt="icon"
                      className="whatsapp"
                    />
                  </a> */}
                  <a href="https://www.instagram.com/beyond_it_info/" target="_blank" rel="noreferrer">
                    <img src="./images/Instagram.svg" alt="icon" />
                  </a>
                  {/* <a href="#linkedin">
                    <img src="./images/linkedin.svg" alt="icon" />
                  </a> */}
                </div>
              </div>
              <div>
                <ul className="footer-ul" id="footer-header-ul2">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Service">Services</Link>
                  </li>
                  <li>
                    <Link to="/Portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/AboutUs">About us</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/Privacy">Privacy policy</Link>
                  </li>
                    
                    
                </ul>
              </div>
            </div>
            <div className="footer-button-email">
              <div className="footer-border"></div>
              <div className="footer-button-texts">
                <div className="footer-email">
                  Beyond it © All rights reserved 2022
                </div>
                <div className="footer-button-text">
                  {/* <a href="/GDPR" target="_blank" rel="noreferrer">
                    <span>GDPR Policy</span>
                  </a> */}
                  <a href="/Privacy" target="_blank" rel="noreferrer">
                    <span>Privacy policy</span>
                  </a>
                  <a href="/Terms" target="_blank" rel="noreferrer">
                    <span>Terms & Conditions</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
