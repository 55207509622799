import React from 'react'
import './textTerms.css'

export default function TextPrivacy() {
  const mailtoHref = "mailto: Support@beyond-it.info?subject=SendMail&body=Description";
  return (
    <div className="textTerms">
      <div className="title-terms">
        <span className='title-terms-name'>Privacy policy</span> <br />
        <span className='date-terms-updated'>Last updated: 7 Dec, 2023</span>
      </div>
      <div className="text-terms-one">
        <span>Thank you for choosing Beyond IT. This Privacy Policy outlines how your personal information is collected, used, and safeguarded.</span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>1. Information Collected: </b>
          <br />
          <span >a. Registration Information: When you register, we collect information such as name, email, and other contact details for communication purposes. <br />
            b. Payment Information: If you use payment services, transaction-related information is collected for payment processing.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>2. Use of Information: </b>
          <br />
          <span >Your information is used for service provision, customer support, service improvement, and relevant communications from Beyond IT.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>3. Privacy Safeguard</b>
          <br />
          <span >Beyond IT is committed to keeping your personal information confidential and will not transfer any information to individuals or companies without your permission.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>4. Shared Information</b>
          <br />
          <span >Your information may be shared with partner companies in certain cases to enhance services, always in compliance with our privacy policies.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>5. Security:</b>
          <br />
          <span >Beyond IT implements security measures to protect your information from unauthorized access and keeps security policies and processes up-to-date.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>6. Changes to Privacy Policy :</b>
          <br />
          <span >Any changes to this Privacy Policy will be published here.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>7. Contact Us: </b>
          <br />
          <span >For any privacy-related questions or requests, please contact us at: <a href={mailtoHref}>Support@beyond-it.info.</a></span>
        </span>
      </div>

    </div>
  )
}
