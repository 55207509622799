import React from 'react'
import Box from '../Box/Box'
import ShinyCard from '../ShinyCard/ShinyCard'
import './client.css'

const profiles = [
  { id: 1, cover: './images/image_9.svg', name: 'Zahra Soroush', job: 'Radio ghasedak',text: 'Beyond IT created an exceptional application. The team was skilled, communicative and responsive, working tirelessly to understand the client`s vision and bring it to life. The end product was highly praised and the client highly recommends Beyond IT.'},

  {
    id: 2,
    cover: './images/image_10.svg',
    name: 'Helen',
    job: 'FFHELEN RADIO',
    text:'Beyond IT developed a new application that exceeded expectations in terms of quality, user-friendliness and reliability. The team was professional and responsive throughout the development process. I highly recommend Beyond IT for your app development services.'
  },
  {
    id: 3,
    cover: './images/image_11.svg',
    name: 'Dr karandish',
    job: 'Newderm',
    text: 'Excellent work on time and reasonable price. The services of your company were very useful for us and we were able to design and provide the work related to our internet services with good quality. Thank you very much.'
  },
  {
    id: 4,
    cover: './images/image_12.svg',
    name: 'Reza Eftekhari',
    job: 'Learn English',
    text:'Beyond IT created a wonderful piece of software from my imagination that I was very happy to look at and work with when it was finished. I strongly recommend the services of Beyond IT. In a few words, it can be said that they will give you creativity, beauty, intelligent engineering.'
  },
  {
    id: 5,
    cover: './images/image_13.svg',
    name: 'Toy Exchange',
    job: 'Toy Exchange',
    text:'Beyond IT created a fantastic and visually appealing application that met all of the client`s needs. The team was knowledgeable, responsive and open to feedback, keeping the client updated throughout the process. The client highly recommends their services'
  },
  {
    id: 6,
    cover: './images/image_14.svg',
    name: 'Xiao Li ',
    job: 'INTROIT',
    text: 'How accurate and beautiful. I really congratulate you for paying attention to the details so patiently and carefully. It was very attractive and beautiful and beyond my imagination that you paid attention to things that I had not considered and created a perfect system. Thank you.'
  },
]

export default function Client() {
  return (
    <div className="client">
      <Box isMiddleColumn>
        <div className="container">
          <div className="commentsProfile">
            <h3 className="comments">Comments of some of our customers...</h3>
            <p className="paraComments">
            See what our clients have to say about our services
            </p>
          </div>
          {profiles.map((user, index) => (
            <ShinyCard index={index}>
              <div className="clientBox" key={user.id}>
                <div className="clientBoxProfile">
                  <div className="clientBoxImg">
                    <img src={user.cover} alt="Client" />
                  </div>
                  <div className="clientBoxName">
                    <p className="profileName">{user.name}</p>
                    <p className="job">{user.job}</p>
                  </div>
                </div>
                <div>
                  <p className="descPara">
                    {user.text}
                  </p>
                </div>
              </div>
            </ShinyCard>
          ))}
        </div>
      </Box>
    </div>
  )
}
