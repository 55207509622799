import React from "react";
import "./radioHelen.css";
import Button from "../button/Button";

export default function RadioHelen() {
  return (
    <div className="OnlineShope RadioHelen">
      <h3 className="title-onlineShope-mobile">Radio Helen app</h3>


      <div className="box-radio">
        <div className="onlineShope-text">
          <div className="download-link">
            <h3 className="title-onlineShope-laptop">Radio Helen app</h3>
            <a href="https://play.google.com/store/apps/details?id=com.ffhelen.radio&pcampaignid=web_share" className="android" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M18.344 8.71c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53c0-.706-.572-1.278-1.279-1.278zm-12.688 0c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53A1.277 1.277 0 0 0 5.656 8.71zm8.889-3.809.768-1.388a.343.343 0 0 0-.133-.47.344.344 0 0 0-.471.135l-.789 1.42c-.588-.235-1.236-.368-1.92-.368s-1.332.132-1.92.367l-.789-1.418a.344.344 0 0 0-.471-.135.345.345 0 0 0-.133.47l.768 1.388C8.072 5.69 7.148 7.099 7.148 8.71c0 .011 0 .021.002.034-.002.006-.002.013-.002.019v.001h9.703V8.71c.001-1.611-.923-3.019-2.306-3.809zM9.76 6.869a.346.346 0 1 1 .005-.693.346.346 0 0 1-.005.693zm4.48 0a.347.347 0 0 1 0-.692.346.346 0 0 1 0 .692zM7.148 16.12c0 .646.525 1.171 1.174 1.171h.586v2.401a1.279 1.279 0 0 0 2.558 0v-2.401h1.066v2.401c0 .707.572 1.278 1.277 1.278.709 0 1.281-.571 1.281-1.278v-2.401h.586c.648 0 1.174-.524 1.174-1.171V9.028H7.148v7.092z"></path></svg></a>

            <a href="https://apps.apple.com/us/app/ffhelen-radio/id1672871423" className="android" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" fill="#fff" viewBox="0 0 50 50">
                <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z"></path>
              </svg>
            </a>
          </div>

          <span>
            Don't you want to have a website that just exists? The effort of
            <span className="Beyond">
              Beyond <img src="./images/Subtract.svg" alt="Subtract" />
            </span>
            specialists is to design a site for you that will make the most of
            it.
            <br /> We always follow these principles Design according to the
            standard of the day Analysis of competitors Design with PS/XD/figam
            based on customer's requirement Compliance with the basic principles
            of UX in design Compliance with the principles of color psychology
            No limit on the number of corrections and edits User experience (UX)
            and user interface (UI) are very decisive components in any digital
            product.
            <br /> Your audience first judges the appearance of your product and
            then decides to use it. A suitable UI can attract more audience to
            your product and a good UX makes using your product enjoyable and
            pleasant, thus making existing users stay, and attracting new users.{" "}
            <br />
            Therefore, a proper UI/UX design makes it easier to communicate with
            users.
          </span>
          <div className="buttonWebsite">
            <Button className="buttonComponent" title="SEE MORE" />
          </div>
        </div>
        <div className="onlineShope-img">
          <img src="./images/Group303.png" alt="Group303" />
        </div>
      </div>
    </div>
  );
}
