import React from "react";
import "./outlet.css";
import Button from "../button/Button";

export default function Outlet() {
  return (
    <div className="OnlineShope plant outlet">
      <h3 className="title-onlineShope-mobile">khazairugs</h3>
      <div className="box-radio">
        <div className="onlineShope-text">
        <div className="download-link">
          <h3 className="title-onlineShope-laptop">khazairugs</h3>
          <a href="https://khazairugs.com" className="android" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="24" height="24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
              </svg>
            </a>
        </div>
          <span>
            Our mission at EnvireTech is to help you focus in your field by
            realizing and supervising your projects with our experts from all
            over the world. <br /> At EnvireTech we offer IT services and
            solutions for building an efficient IT infrastructure, data centers,
            offices or any kind of workplaces. <br /> EnvireTech's team works
            around the clock to provide you with end-to-end services for the
            efficient and effective implementation of your project, so your
            claims can be completed on time and within budget. Our head office
            is in Hungary and together with our partners in the Netherlands,
            Australia, Germany and Great Britain we can also serve you Globally.
            EnvireTech's team works around the clock to provide you with
            end-to-end services for the efficient and effective implementation
            of your project, so your claims can be completed on time and within
            budget.
          </span>
          <div className="buttonWebsite">
            <Button className="buttonComponent" title="SEE MORE" />
          </div>
        </div>
        <div className="onlineShope-img">
          <img src="./images/1547.png" alt="Group303" />
        </div>
      </div>
    </div>
  );
}
