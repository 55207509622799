import React from 'react'
import './textTerms.css'

export default function TextTerms() {
  return (
    <div className="textTerms">
      <div className="title-terms">
        <span className='title-terms-name'>Terms & Conditions</span> <br />
        <span className='date-terms-updated'>Last updated: 19 Jan, 2022</span>
      </div>
      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>1. Introduction: </b>
          <br />
          <span >Welcome to Beyond IT! These Terms and Conditions govern your use of our website, services, applications, and any related content.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>2. Service Offerings: </b>
          <br />
          <span >
            a. Website and Mobile App Design: We provide professional design services for websites and mobile applications tailored to meet your unique requirements.
            <br />
            b. Software Development: Our team creates customized software solutions to enhance your business operations.
            <br />
            c. Hosting Services: Beyond IT offers reliable hosting services to ensure optimal performance and security for your digital presence. <br />
            d. Network Solutions: We specialize in designing and optimizing networks to meet the connectivity needs of your business. <br />
            e. Digital Marketing: Our services include online marketing strategies to boost your digital presence and reach your target audience effectively.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>3. User Responsibilities: </b>
          <br />
          <span >a. Accuracy of Information: Users are responsible for providing accurate and up-to-date information for any service engagement. <br />
            b. Security: Users must maintain the confidentiality of their account credentials and notify Beyond IT immediately of any unauthorized access.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>4. Payments and Fees: </b>
          <br />
          <span >a. Quotations: All quotations provided by Beyond IT are valid for the specified period and subject to change. <br />
            b. Payment Terms: Payment terms will be outlined in project-specific agreements. Failure to comply may result in the suspension of services.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>5. Intellectual Property: </b>
          <br />
          <span >a. Ownership: All intellectual property rights developed during the provision of services shall belong to the client, unless otherwise agreed upon. <br />
b. Client Content: Clients retain ownership of their provided content and grant Beyond IT the necessary licenses to use, modify, and display such content.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>6. Limitation of Liability: </b>
          <br />
          <span >Beyond IT is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>7. Termination: </b>
          <br />
          <span >Either party may terminate services with a written notice. Beyond IT reserves the right to suspend or terminate services immediately for any violation of these terms.</span>
        </span>
      </div>

      <div className="text-terms-one">
        <span>
          <b className='text-Purple'>8. Governing Law: </b>
          <br />
          <span >These Terms & Conditions are governed by and construed in accordance with the laws of the United States. Please consider that our company operates under the laws of the United States.</span>
        </span>
      </div>

    </div>
  )
}
